<template>
    <div class="grid-view">
        <v-row align="start" justify="start" dark>
            <v-col cols="12" class="d-flex flex-start align-center">
                <div class="text-h6" v-html="title"></div>
                <v-divider vertical class="mx-2" />

                <v-btn outlined small color="purple darken-2" class="ml-auto white--text" @click.prevent.stop="toggleFilter(true)">
                    <v-icon left dark>
                        mdi-filter-variant
                    </v-icon>
                    <span>Фильтр</span>
                </v-btn>

                <v-spacer />

                <slot name="title.right" />

                <v-btn v-if="create" :to="urlCreate" color="purple darken-2" class="ml-auto white--text">
                    <v-icon left dark>
                        mdi-plus
                    </v-icon>
                    <span v-html="createTitle"></span>
                </v-btn>
            </v-col>
        </v-row>

        <v-row align="start" justify="start" dark>
            <v-col cols="12">
                <!-- Data table -->
                <v-data-table
                    :loading="loading"
                    dense
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="itemsPerPage"
                    :footer-props="{
                        'disable-items-per-page': true,
                        'items-per-page-options': [],
                        'show-current-page': true,
                        'show-first-last-page': true
                    }"
                    :calculate-widths="true"
                    :headers="headers"
                    :items="items"
                    item-key="id"
                    class="elevation-1"
                  >
                    <template v-slot:item.edit="{ item }">
                        <v-icon v-if="checkEditPermission(item)" small class="mr-2" @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <template v-slot:item.remove="{ item }">
                        <v-icon v-if="checkDeletePermission(item)" small @click="remove(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                  </v-data-table>

                  <!-- Delete item confirm dialog -->
                  <v-dialog v-model="popup.remove" max-width="500px">
                    <v-card>
                        <v-card-title class="headline">Вы действительно хотите удалить этот элемент?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color="purple darken-2" @click="remove(null)">Отмена</v-btn>
                            <v-btn dark color="purple darken-2" outlined @click="remove(selectedOne)">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

        <filter-view 
            v-model="popup.filter" 
            v-slot="filterScope"
            :store-module="storeModule" 
            :query-filters="queryFilters"
            @close="toggleFilter(false)"
            @filter="applyFilter"
        >
            <slot name="filter" :filter="filterScope.model" />
        </filter-view>
    </div>
</template>
<script>
import FilterView from './FilterView.vue'
export default {
    name: 'GridView',
    components: { FilterView },
    props: {
        title: String,
        filter: {
            type: Boolean,
            default: false
        },
        create: {
            type: Boolean,
            default: false
        },
        createTitle: {
            type: String,
            default: 'Создать'
        },
        urlCreate: String,
        urlUpdate: String,
        storeModule: String,
        actionFetch: {
            type: String,
            default: 'list'
        },
        actionDelete: {
            type: String,
            default: 'delete'
        },
        headers: Array,
        format: {
            type: Function,
            default: (items) => {
                return items;
            }
        },
        checkEditPermission: {
            type: Function,
            default: function() {
                return true;
            }
        },
        checkDeletePermission: {
            type: Function,
            default: function() {
                return true;
            }
        },
        // Если переданы строковые значения в массиве, то указанные query в route будут отслеживаться
        // и найденные значения будут использоваться в фильтре при запросе к данным
        queryFilters: { type: Array, default: () => [] }
    },
    data() {
        return {
            loading: true,
            popup: {
                remove: false,
                filter: false
            },
            selected: [],
            selectedOne: null,
            total: 0,
            options: {
                ... _.cloneDeep(_.get(this, `$store.state.${this.storeModule}.grid.index.opitions`, {})),
                filter: _.assign(
                        this.getFilter(),
                        _.pick(this.$route.query, this.queryFilters)
                    )
            },
            items: [],
            fetchKey: null
        }
    },
    watch: {
        '$route.query': {
            handler () {
                if (!this.queryFilters.length) { return false; }
                
                this.options.filter = _.assign(
                    _.cloneDeep(_.get(this, `$store.state.${this.storeModule}.filter`)),
                    _.pick(this.$route.query, this.queryFilters)
                );
                this.options.page = 1;
            },
            deep: true
        },
        options: {
            handler () {
                this.$store.dispatch(`${this.storeModule}/updateGridOptions`, {
                    index: _.omit(this.options, ['filter'])
                });
                this.fetch();
            },
            deep: true
        }
    },
    computed: {
        itemsPerPage () {
            return this.options?.options?.itemsPerPage || 10
        }
    },
    methods: {
        async fetch() {
            if (this.fetchKey === JSON.stringify(this.options) && this.loading) { return false; }
            this.loading = true;
            this.fetchKey = JSON.stringify(this.options);
            const options = this.cleanFetchOptions(this.options)

            const {success, data, error} = await this.$store.dispatch(`${this.storeModule}/${this.actionFetch}`, options);
            if(success)
            {
                this.total = _.get(data, 'total', 0);
                // this.items = _.get(data, 'items', []);
                this.items = this.format(_.get(data, 'items', []));
            }
            else
            {
                console.log(error);
            }
            this.loading = false;
        },
        cleanFetchOptions (data) {
            const options = _.cloneDeep(data);
            // Remove null values
            for (const key in options.filter) {
                if (options.filter[key] === null) {
                    delete options.filter[key]
                }
            }
            // Pagination
            options.paginations = true;
            options.pageSize = options?.itemsPerPage || this.itemsPerPage;
            // Sorting
            options.sort = {}
            options.sortBy.forEach((key, index) => {
                options.sort[key] = options.sortDesc[index] ? 'ASC' : 'DESC'
            })
            // Removing excess fields
            delete options.options;
            delete options.sortBy;
            delete options.sortDesc;
            delete options.itemsPerPage;
            return options;
        },
        edit(item) {
            const urlUpdate = _.replace(this.urlUpdate, ':id', item.id);
            return this.$router.push(urlUpdate).catch(() => {});
        },
        async remove(item) {
            // Cancel remove
            if(_.isNil(item))
            {
                this.selectedOne = null;
                this.popup.remove = false;
                return ;
            }
            // Confirm remove popup
            if(!this.popup.remove)
            {
                this.selectedOne = item;
                this.popup.remove = true;
                return ;
            }
            // Remove handler
            if(this.popup.remove === true)
            {
                this.loading = true;
                const {success, error} = await this.$store.dispatch(`${this.storeModule}/${this.actionDelete}`, {
                    id: _.get(this.selectedOne, 'id', 0)
                });
                if(success)
                    await this.fetch();
                else
                {
                    console.log(error);
                }

                this.loading = false;
                this.popup.remove = false;
                return ;
            }
        },
        getFilter() {
            return _.omitBy(_.cloneDeep(_.get(this, `$store.state.${this.storeModule}.filter`, {})), _.isNil);
        },
        toggleFilter(value) {
            this.popup.filter = value === true;
        },
        applyFilter() {
            this.options.filter = this.getFilter();
            this.options.page = 1;
        }
    }
}
</script>
<style lang="scss">
    .grid-view{
        table {
            thead {
                th {
                    white-space: nowrap!important;
                }
            }
        }
    }
</style>